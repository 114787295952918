import { useState } from "react";
import { useDispatch } from "react-redux";
import { emptyStorage, logIn, logOut } from "../../api/user";
import { UserRole } from "../../constants";
import { updateCurrentUser } from "../../tenants";
import { CommonForm } from "../common/CommonForm";
import { NotificationLayer } from "../common/NotificationLayer";
import { LoginFormFields } from "../forms/LoginFormFields";
import "./../../i18n";
import appConfig from "../../appConfig";

function WaaLogin() {
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const submitForm = (values) => {
    const p = logIn(values);

    p.then((user) => {
      if (user.user_role === UserRole.WAA) {
        dispatch(updateCurrentUser(user));
      } else {
        emptyStorage();
        setError(
          "Falsche Rolle. Sie besitzen als dieser Anwender nicht die erforderlichen Rechte."
        );
      }
    }).catch(emptyStorage);

    return p;
  };

  const { debug } = appConfig;
  const ivs = {
    email: debug ? "waa@it-ref.de" : "",
    password: debug ? "1Passwort!" : "",
  };

  return (
    <div className="col-md-8">
      <CommonForm
        translationScope="tenant_login"
        fieldsComponent={LoginFormFields}
        initialValues={ivs}
        apiEndpoint={submitForm}
      >
        <NotificationLayer
          message={error}
          customTrigger={error}
          className="danger"
        />
      </CommonForm>
    </div>
  );
}

export default WaaLogin;
