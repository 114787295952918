import React from "react";
import { Link } from "react-router-dom";
import UserQuickLinks from "./login/UserQuickLinks";
import PublicQuickLinks from "./PublicQuickLinks";

function Navigation() {
  return (
    <nav className="navbar navbar-default" role="navigation">
      <div className="container">
        <div className="navi">
          <div className="branding-header">
            <div className="main-logo">
              <Link to="/">
                <img
                  src={window.ITREF.config.logo}
                  alt="HNO Netzwerk Bayern Logo"
                  title="HNO Netzwerk Bayern Logo"
                />
              </Link>
            </div>
            <UserQuickLinks />
            <PublicQuickLinks />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
