import React from "react";
import AllEvents from "./AllEvents";
import CalendarList from "./CalendarList";
import CanceledEvents from "./CanceledEvents";
import NewAppointments from "./NewAppointments";
import NewMembers from "./NewMembers";
import PasswordReset from "./PasswordReset";
import SettingsElement from "./SettingsElement";

const HtkDashboardIndex = () => {
  return (
    <div className="row dashboard">
      <NewAppointments />
      <CanceledEvents />
      <NewMembers />
      <PasswordReset />
      <CalendarList />
      <AllEvents />
      <SettingsElement />
    </div>
  );
};

export default HtkDashboardIndex;
