import { useSelector } from "react-redux";
import { UserRole } from "../constants";
import WaaLogin from "./login/WaaLogin";
import TenantIndex from "./TenantIndex";

export const TenantDefault = () => {
  const { currentUser } = useSelector((state) => state);

  return (
    <div>
      {currentUser && currentUser.user_role === UserRole.WAA ? (
        <TenantIndex />
      ) : (
        <WaaLogin />
      )}
    </div>
  );
};
