import PropTypes from "prop-types";
import { ActionButton } from "./ActionButton";
export function RetractButton({ onClick }) {
  return (
    <div className="row">
      <div className="col-md-12 margin-bottom">
        <ActionButton onClick={onClick} title="appointments.reject_and_free" />
      </div>
    </div>
  );
}

RetractButton.propTypes = {
  onClick: PropTypes.func,
};
