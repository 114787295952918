import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
export const LabeledRow = ({ children, label, required, htmlFor }) => {
  return (
    <Row className="form-group">
      <Col sm={4}>
        <label className="control-label" htmlFor={htmlFor}>
          {label}
          {required && <abbr title="Pflichtfeld">*</abbr>}
        </label>
      </Col>
      <Col sm={8}>{children}</Col>
    </Row>
  );
};

LabeledRow.defaultProps = {
  required: false,
};

LabeledRow.propTypes = {
  children: PropTypes.any,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};
