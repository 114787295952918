import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie, setCookie } from "../shared";

function CookieConsent() {
  const [show, setShow] = useState(true);
  const [t] = useTranslation();

  const handleAcceptButton = () => {
    setCookie("acceptedCookies", "yes", 365);
    setShow(false);
  };

  if (getCookie("acceptedCookies") || show == false) return <></>;

  return (
    <div className="cookie-notice">
      <i>{t("cookie_consent.info")}</i>
      <a href="https://www.lmu-klinikum.de/cookies/41fa08aa421f0f09">
        {t("cookie_consent.link_text")}
      </a>
      <button
        onClick={handleAcceptButton}
        className="btn btn-default green-button"
      >
        {t("accept")}
      </button>
    </div>
  );
}

export default CookieConsent;
