import { useTranslation } from "react-i18next";
import { LabeledRow } from "../common/LabeledRow";

export const LoginFormFields = ({ formik }) => {
  const [t] = useTranslation();

  return (
    <>
      <LabeledRow label={t("email")} required={true}>
        <input
          name="email"
          type="email"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.email}
        />
      </LabeledRow>
      <LabeledRow label={t("password")} htmlFor="password" required={true}>
        <input
          defaultValue={formik.values.password}
          onChange={formik.handleChange}
          className="password optional form-control"
          type="password"
          autoComplete="current-password"
          id="password"
        />
      </LabeledRow>
    </>
  );
};
