import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation, useParams } from "react-router";
import { Link, Switch } from "react-router-dom";
import {
  clearCurrentEventSeries,
  updateCurrentEventSeries,
} from "../../actions/app";
import { getAllEventSeries, getEventSeries } from "../../api/event_series_meta";
import { getEventSeriesDates } from "../../shared";
import { LabeledRow } from "../common/LabeledRow";
import { ContentContainer } from "../common/ContentContainer";
import { EventElement } from "../events/EventElement";
import { EventSeriesDetailRows } from "./EventSeriesDetailRows";
import { EventSeriesEditor } from "./EventSeriesEditor";
import { Col, Row } from "react-bootstrap";
import { CalendarLink } from "../common/CalendarLink";
import { CalendarTitle } from "../common/CalendarTitle";
import { CommonSpinner } from "../common/CommonSpinner";
import { getCalendars } from "../../api/calendar";
import { ModalID } from "../../constants";

export const EventSeriesIndex = () => {
  const [t] = useTranslation();
  const { currentEventSeries, showModal } = useSelector(
    (state) => state.appState
  );
  const { calendars } = useSelector((state) => state.dashboard);
  const [eventSeries, setEventSeries] = useState([]);
  const dispatch = useDispatch();
  const [startTimeDate, endTimeDate] = getEventSeriesDates(currentEventSeries);
  const { series_id } = useParams();
  const url = useLocation();
  const [openId, setOpenId] = useState(-1);

  useEffect(() => {
    getAllEventSeries()
      .then((res) => {
        setEventSeries(res.data.data);
        setOpenId(`cal-link-${res.data.data[0].id}`);
      })
      .catch((res) => setEventSeries([]));

    if (!series_id || series_id === "create") {
      dispatch(clearCurrentEventSeries());
      return;
    }

    reloadEventSeries();
  }, [series_id, url]);

  useEffect(() => {
    if (!calendars?.length) getCalendars();
  }, []);

  useEffect(() => {
    if (!showModal.includes(ModalID.EVENT)) reloadEventSeries();
  }, [showModal]);

  const reloadEventSeries = () => {
    if (!series_id || series_id === "create") return;
    getEventSeries(series_id)
      .then(({ data }) => {
        dispatch(updateCurrentEventSeries(data.data.attributes));
        setOpenId(`cal-link-${data.data.attributes.calendar_id}`);
      })
      .catch(console.log);
  };

  const eventsSorted = {};
  eventSeries.forEach((serie) => {
    let { calendar_id } = serie.attributes;
    if (!calendar_id) calendar_id = "none";
    if (!eventsSorted[calendar_id]) {
      eventsSorted[calendar_id] = [];
    }
    eventsSorted[calendar_id].push(serie);
  });

  const seriesOverview = () => {
    return (
      <Accordion defaultActiveKey={openId.toString()}>
        {Object.keys(eventsSorted).map((calId, index) => {
          const eventList = eventsSorted[calId];
          const calendarKey = `cal-link-${calId}`;
          return (
            <Card key={calendarKey}>
              <Accordion.Toggle as={Card.Header} eventKey={calendarKey}>
                <CalendarTitle id={calId} count={eventList.length} />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={calendarKey}>
                <ul>
                  {eventList.map((series) => {
                    return (
                      <li
                        key={series.id}
                        className={series_id === series.id ? "bold" : ""}
                      >
                        <Link to={`/event_series/${series.id}`} key={series.id}>
                          {series.attributes.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    );
  };

  if (!eventSeries) return <CommonSpinner />;

  return (
    <ContentContainer title={t("event.series.series")}>
      <Row className="event-series-index">
        <Col sm={3}>
          <div className="margin-bottom">
            <Link to="/event_series/create">
              <Button variant="primary" disabled={series_id === "create"}>
                <i className={`fa fa-plus`}></i>
                {t("add")}
              </Button>
            </Link>
          </div>
          {seriesOverview()}
        </Col>
        <Col sm={9}>
          <Switch>
            <Route exact path="/event_series/:series_id/edit">
              {currentEventSeries?.id && (
                <>
                  <div className="float-right margin-bottom">
                    <Link to={`/event_series/${currentEventSeries.id}`}>
                      <Button variant="primary">
                        <i className={`fa fa-times`}></i>
                        {t("cancel")}
                      </Button>
                    </Link>
                  </div>
                  <EventSeriesEditor />
                </>
              )}
            </Route>
            <Route exact path="/event_series/create">
              <div className="float-right margin-bottom">
                <Link to={`/event_series`}>
                  <Button variant="primary">
                    <i className={`fa fa-times`}></i>
                    {t("cancel")}
                  </Button>
                </Link>
              </div>
              <EventSeriesEditor />
            </Route>
            <Route exact path="/event_series/:series_id">
              {currentEventSeries?.id && (
                <>
                  <div className="float-right margin-bottom">
                    <Link to={`/event_series/${currentEventSeries.id}/edit`}>
                      <Button variant="primary">
                        <i className={`fa fa-edit`}></i>
                        {t("edit")}
                      </Button>
                    </Link>
                  </div>
                  <h2>{currentEventSeries.title}</h2>
                  {currentEventSeries.calendar_id && (
                    <CalendarLink id={currentEventSeries.calendar_id} />
                  )}
                  <hr />
                  <EventSeriesDetailRows
                    values={currentEventSeries}
                    startTimeDate={startTimeDate}
                    endTimeDate={endTimeDate}
                  />
                  {!!currentEventSeries.events && (
                    <LabeledRow label={t("possible_dates")}>
                      {currentEventSeries.events.map((event) => (
                        <EventElement details={event} key={event.id} />
                      ))}
                    </LabeledRow>
                  )}
                </>
              )}
            </Route>
          </Switch>
        </Col>
      </Row>
    </ContentContainer>
  );
};
