import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { tenantStore } from "../tenants";
import { TenantDefault } from "./TenantDefault";

const TenantMain = () => {
  return (
    <Provider store={tenantStore}>
      <Router>
        <div className="page-content">
          <div className="content-wrap">
            <div className="container">
              <div className="content-area">
                <TenantDefault />
              </div>
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  );
};

export default TenantMain;
