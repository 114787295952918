import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const CalendarLink = ({ id }) => {
  const [t] = useTranslation();
  return (
    <p className="small">
      <Link to={`/calendars/${id}`}>
        {t("calendar.view_events")}
        <span style={{ marginLeft: "4px" }}>
          <i type="fas" className="fa fa-list"></i>
        </span>
      </Link>
    </p>
  );
};

CalendarLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
