import { useEffect } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import { Link, Switch, useParams } from "react-router-dom";
import { getCalendars } from "../../api/calendar";
import { ACCESS_RIGHTS, UserRole } from "../../constants";
import { useUserRole } from "../../custom_hooks/user";
import { hasRight } from "../../shared";
import { ContentContainer } from "../common/ContentContainer";
import CalendarCreate from "./CalendarCreate";
import { CalendarEdit } from "./CalendarEdit";

const CalendarIndex = () => {
  const [t] = useTranslation();
  const { calendars } = useSelector((state) => state.dashboard);
  const { id } = useParams();

  const role = useUserRole();

  useEffect(() => {
    getCalendars();
  }, []);

  const linkForRole = (calendar) => {
    let path = `/calendars/${calendar.id}`;
    if (role !== UserRole.NAA) path += "/edit";

    return (
      <Link to={path} key={calendar.id}>
        {calendar.attributes.title}
      </Link>
    );
  };

  const editable = hasRight(ACCESS_RIGHTS.calendar);

  return (
    <ContentContainer title={t("calendar.title_plural")}>
      <Row>
        <Col sm={2}>
          {editable && (
            <div className="margin-bottom">
              <Link to="/calendars/create">
                <Button variant="primary" disabled={id === "create"}>
                  <i className={`fa fa-plus`}></i>
                  {t("add")}
                </Button>
              </Link>
            </div>
          )}
          <ul>
            {calendars.map((calendar) => {
              return (
                <li
                  key={calendar.id}
                  className={id === calendar.id ? "bold" : ""}
                >
                  {linkForRole(calendar)}
                </li>
              );
            })}
          </ul>
        </Col>
        {editable && (
          <Col sm={10}>
            <Switch>
              <Route exact path="/calendars/create">
                <CalendarCreate />
              </Route>
              <Route exact path="/calendars/:id/edit">
                <CalendarEdit id={id} />
              </Route>
            </Switch>
          </Col>
        )}
      </Row>
    </ContentContainer>
  );
};

export default CalendarIndex;
