import React, { useEffect, useState } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Route, useHistory } from "react-router";
import { Link, Switch } from "react-router-dom";
import { getTenants, createTenant, updateTenant } from "../api/tenant";
import { CommonForm } from "./common/CommonForm.jsx";
import CreateTenantFormFields from "./forms/CreateTenantFormFields";
import * as Yup from "yup";
import "../i18n";
import { TenantEditor } from "./tenant/TenantEditor";
import { TenantShow } from "./tenant/TenantShow";

function TenantIndex() {
  const [tenants, setTenants] = useState([]);
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    async function fetchTenants() {
      let response = await getTenants();
      setTenants(response.data);
    }
    fetchTenants();
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(
      t("validation.field_required", { field: t("tenant.edit.title") })
    ),
  });

  const onSubmit = async (values) => {
    const res = await createTenant(values);
    setTenants(res.data);
  };

  const createNew = () => {
    history.push("/tenants/create");
  };

  return (
    <Row>
      <Col sm={2}>
        <div className="margin-bottom">
          <Button variant="primary" onClick={createNew}>
            <i className={`fa fa-plus`}></i>
            {t("add")}
          </Button>
        </div>
        <ul>
          {tenants.map((tenant) => {
            return (
              <li key={tenant.id}>
                <Link to={`/tenants/${tenant.id}`}>{tenant.title}</Link>
              </li>
            );
          })}
        </ul>
      </Col>
      <Col sm={10}>
        <Switch>
          <Route exact path="/tenants/create">
            <CommonForm
              apiEndpoint={onSubmit}
              fieldsComponent={CreateTenantFormFields}
              validationSchema={validationSchema}
              translationScope="tenant.create"
              initialValues={{ title: "", domain: "", subdomain: "" }}
            />
          </Route>
          <Route exact path="/tenants/:id/edit">
            <TenantEditor />
          </Route>
          <Route exact path="/tenants/:id">
            <TenantShow />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
}

export default TenantIndex;
