import PropTypes from "prop-types";
import React from "react";
import { Formik, Field, Form } from "formik";
import FormikDatePicker from "../FormikDatePicker";
import { LabeledRow } from "../common/LabeledRow";

function EventForm({ onSubmit, initialValues, children }) {
  return (
    <div className="form-group">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => (
          <Form>
            <div className="form-inputs">
              <LabeledRow label="Titel">
                <Field
                  className="form-control string required"
                  id="title"
                  name="title"
                  type="text"
                ></Field>
              </LabeledRow>
              <LabeledRow label="Zeitspanne des Termins">
                <FormikDatePicker
                  name="start"
                  format=""
                  showTimeSelect={true}
                  dateFormat={"dd.MM.yyyy H:mm"}
                />
                &nbsp; bis &nbsp;
                <FormikDatePicker
                  name="end"
                  showTimeSelect={true}
                  dateFormat={"dd.MM.yyyy H:mm"}
                />
              </LabeledRow>
              {children}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

EventForm.propTypes = {
  children: PropTypes.any,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default EventForm;
