import { useSelector } from "react-redux";
import { deleteUser, logOut } from "../../api/user";
import { useCurrentUser } from "../../custom_hooks/user";
import { CommonForm } from "../common/CommonForm";
import { DeleteUserFormFields } from "../forms/DeleteUserFormFields";

export const DeleteUserDangerZone = () => {
  const currentUser = useCurrentUser();
  const { password } = useSelector((state) => state.appState);
  const { id } = currentUser;

  const triggerDelete = (values) => {
    return new Promise((resolve, reject) => {
      if (values.password !== password) {
        reject();
      } else {
        deleteUser(id).then((res) => {
          resolve(res);
          logOut();
        });
      }
    });
  };

  return (
    <div className="alert alert-danger">
      <CommonForm
        translationScope="account_settings.delete_user"
        apiEndpoint={triggerDelete}
        initialValues={{ password: "" }}
        fieldsComponent={DeleteUserFormFields}
      />
    </div>
  );
};
