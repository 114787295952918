import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createCalendar, getCalendars } from "../../api/calendar";
import { CommonForm } from "../common/CommonForm";
import { LabeledRow } from "../common/LabeledRow";
import { CalendarFormFields } from "../forms/CalendarFormFields";
import { CalendarPermissions } from "./CalendarPermissions";
import { useHistory } from "react-router";
import { calendarValidationSchema } from "../../constants";

function CalendarCreate() {
  const [t] = useTranslation();

  const [permissionedUsers, setPermissionedUsers] = useState([]);
  const history = useHistory();

  const sendForm = (values) => {
    createCalendar(
      values,
      permissionedUsers.map(({ id }) => id)
    )
      .then((res) => {
        getCalendars();
        history.push(`/calendars/${res.data.data.id}`);
      })
      .catch(setApiResponse);
  };

  const onUsersUpdate = (nu) => {
    setPermissionedUsers(nu);
  };

  const validationSchema = calendarValidationSchema(
    t("validation.field_required", { field: t("title") })
  );

  return (
    <CommonForm
      apiEndpoint={sendForm}
      fieldsComponent={CalendarFormFields}
      translationScope="calendar.create"
      validationSchema={validationSchema}
      initialValues={{ title: "", description: "" }}
    >
      <LabeledRow label={t("calendar.permitted_users")}>
        <CalendarPermissions
          permissionedUsers={permissionedUsers}
          onUpdate={onUsersUpdate}
        />
      </LabeledRow>
    </CommonForm>
  );
}

export default CalendarCreate;
