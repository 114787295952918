// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
const thunkMiddleware = require("redux-thunk").default;
const UPDATE_CURRENT_TENANT = 'UPDATE_CURRENT_TENANT'
const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

/**
 * Update user in tenant store
 * @param {object} user 
 * @returns action
 */
export const updateCurrentUser = user => {
  return { type: UPDATE_CURRENT_USER, payload: user }
}

function currentTenant(state = null, action) {
  switch (action.type) {
    case UPDATE_CURRENT_TENANT:
      return action.tenant
    case UPDATE_CURRENT_USER:
        return {
          ...state,
          currentUser: action.payload,
        };
  }
  return state
}
function currentUser(state = null, action) {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return action.payload;
  }
  return state
}

const tenantReducer = combineReducers({ currentTenant, currentUser });

export const tenantStore = createStore(
  tenantReducer,
  {},
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
