export const WEBSOCKET_URL = window.location.host + "/ws";
export const DEFAULT_EVENT_BLOCKED_SECONDS = 60 * 5; // 5 minutes
export const UNBLOCK_WARNING_BEFORE_SECONDS = 60; // 5 minutes
import * as Yup from "yup";

export const ModalID = {
  EVENT: "eventModal",
  CONFIRMATION: "confirmationModal",
  ACCEPT_PASSWORD_RESET: "acceptPasswordResetModal",
  DENY_PASSWORD_RESET: "denyPasswordResetModal",
};

export const MemberMapDefaults = {
  DEFAULT_ZOOM_LEVEL: 13,
  MUNICH_CENTER_LAT_LNG: {
    lat: 48.137252,
    lng: 11.575521,
  },
};

export const PasswordResetStatus = {
  SET_ON_DREAMIN_FORT: "set_on_dreamin_fort",
  INACTIVE: "inactive",
  INITIALIZED: "initialized",
};

export const AppointmentStatus = {
  ACCEPTED: "accepted",
  CANCELED: "canceled",
};

export const UserRole = {
  WAA: "waa",
  HTK: "htk",
  TTK: "ttk",
  NAA: "naa",
};

export const DEFAULT_EVENT_LENGTH_SECONDS = 3600;

export const ACCESS_RIGHTS = {
  calendar: "calendar",
  confirm_users: "confirm_users",
  invite_ttks: "invite_ttks",
  user_overview: "user_overview",
};

export const calendarValidationSchema = (requiredMessage) => {
  Yup.object().shape({ title: Yup.string().required(requiredMessage) });
};
