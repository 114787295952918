import { ActionButton } from "./ActionButton";
import { RetractButton } from "./RetractButton";

export function AppointmentCancelOptions({ onCancel, onRetract }) {
  return (
    <div>
      <h4>Ablehnung Patiententermin</h4>
      <div className="row">
        <div className="col-md-12">
          <RetractButton onClick={onRetract} />
          <ActionButton
            desc="appointments.cancellation_description"
            title="appointments.cancel_booking"
            onClick={onCancel}
          />
        </div>
      </div>
    </div>
  );
}
