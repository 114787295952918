import i18n from "i18next"
import translations from "components/import_translations"

const lang = document.documentElement.lang
let language = "de"
if (lang) {
  language = lang.split("-")[0]
}
export const currentLanguage = language;
export default i18n.init({
  fallbackLng: "de",
  lng: currentLanguage,
  debug: false,
  resources: translations,
  interpolation: {
    prefix: "%{",
    suffix: "}",
    prefixEscaped: "%\\{",
    suffixEscaped: "\\}",
  },
})
