import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateShowModal } from "../../actions/app";
import { ActionButton } from "../appointment/ActionButton";

export const CommonModal = ({ children, title, modalId }) => {
  const { showModal } = useSelector((state) => state.appState);
  const dispatch = useDispatch();
  const visible = showModal.includes(modalId);

  const hideModal = () => dispatch(updateShowModal(modalId));

  return (
    <Modal centered animation={false} show={visible} onHide={hideModal}>
      <Modal.Header closeButton>
        <div className="modal-title h4">{title}</div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <ActionButton onClick={hideModal} title={"close"} />
      </Modal.Footer>
    </Modal>
  );
};

CommonModal.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  modalId: PropTypes.string.isRequired,
};
