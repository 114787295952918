import ax from "../packs/axios";
import { getCalendars } from "./calendar";

/**
 * Retrieve details for an event
 * @param {number} id
 * @returns Promise
 */
export const getEvent = (id) => {
  return ax.get(`/events/${id}`);
};

export const updateEvent = (id, params) => {
  return ax.patch(`/events/${id}`, { ...params });
};

export const destroyEvent = async (id) => {
  return await ax.delete(`/events/${id}`);
};

export const freeEvent = (id) => ax.patch(`/events/${id}/free`);

export const updateEventStatusAndReloadCalendars = (id, newStatus) => {
  const promise = updateEvent(id, { status: newStatus });
  promise.then(getCalendars);
  return promise;
};

export const cancelEvent = async (eventId, reason = null) => {
  return await ax.patch(`/events/${eventId}/cancel`, {reason});
};

export const createEvent = (values, calendar_id) => {
  const params = {
    start: values.start,
    end: values.end,
    title: values.title,
    calendar_id,
  };
  return ax.post(`/events`, params);
};

export const blockEvent = (eventId) => {
  return ax.patch(`/events/${eventId}/block`);
};

export const unblockEvent = (eventId) => {
  return ax.patch(`/events/${eventId}/unblock`);
};

export const getAppointmentForEvent = (eventId) => {
  return ax.get(`/events/${eventId}/appointment`);
};


/**
 * 
 * @param {object} params | i.e. status = 'requested' 
 * @returns appointment list
 */
 export const getEvents = (params) => {
  return ax.get(`/events?${new URLSearchParams(params).toString()}`);
};
