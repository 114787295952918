import { useTranslation } from "react-i18next";
import { LabeledRow } from "./../common/LabeledRow";

export const DeleteUserFormFields = ({ formik }) => {
  const [t] = useTranslation();

  return (
    <LabeledRow label={t("password")} required={true}>
      <input
        name="password"
        type="password"
        className="form-control"
        onChange={formik.handleChange}
        value={formik.values.password}
      />
    </LabeledRow>
  );
};
