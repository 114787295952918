import { useEffect } from "react";
import { getCalendars } from "../api/calendar";
import HtkDashboardIndex from "../components/dashboard/HtkDashboardIndex";
import NaaDashboardIndex from "./dashboard/NaaDashboardIndex";
import { UserRole } from "../constants";
import { useCurrentUser } from "../custom_hooks/user";
import { store } from "../store/store";
import { CommonSpinner } from "./common/CommonSpinner";
import TtkDashboardIndex from "./dashboard/TtkDashboardIndex";

function Dashboard() {
  const currentUser = useCurrentUser();

  useEffect(() => {
    const { dashboard } = store.getState();

    if (dashboard && !dashboard.calendars && !dashboard.calendars.length) {
      getCalendars();
    }
  }, [currentUser]);

  if (!currentUser) return <CommonSpinner />;

  const { user_role } = currentUser;

  switch (user_role) {
    case UserRole.HTK:
      return <HtkDashboardIndex />;
    case UserRole.TTK:
      return <TtkDashboardIndex />;
    default:
      return <NaaDashboardIndex />;
  }
}

export default Dashboard;
