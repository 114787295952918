import { useTranslation } from "react-i18next";
import { LabeledRow } from "./../common/LabeledRow";

export const UpdatePasswordFormFields = ({ formik }) => {
  const [t] = useTranslation();

  return (
    <>
      <LabeledRow label={t("reset_password.password")} required={true}>
        <input
          name="password"
          type="password"
          autoComplete="new-password"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
      </LabeledRow>
      <LabeledRow
        label={t("reset_password.password_confirmation")}
        required={true}
      >
        <input
          name="passwordConfirmation"
          type="password"
          autoComplete="new-password"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.passwordConfirmation}
        />
      </LabeledRow>
      <LabeledRow label={t("reset_password.auth_code")} required={true}>
        <input
          name="authCode"
          type="password"
          autoComplete="new-password"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.authCode}
        />
      </LabeledRow>
      <LabeledRow
        label={t("reset_password.auth_code_confirmation")}
        required={true}
      >
        <input
          name="authCodeConfirmation"
          type="password"
          autoComplete="new-password"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.authCodeConfirmation}
        />
      </LabeledRow>
      <p className="indicator-red">{t("reset_password.reload_warning")}</p>
    </>
  );
};
