import ax from "../packs/axios";

/**
 * Gets array of all event series from API
 * @returns array of event series
 */
export const getAllEventSeries = () => {
  return ax.get(`/event_series_meta`);
};

/**
 * Gets event series details from API
 * @param {number} id
 * @returns array of event series or details for given ID
 */
export const getEventSeries = (id) => {
  return ax.get(`/event_series_meta/${id}`);
};

export const createEventSeries = (values) => {
  return ax.post(`/event_series_meta`, values);
};

export const updateEventSeries = (values) => {
  return ax.patch(`/event_series_meta/${values.id}`, values);
};
export const deleteEventSeries = (id) => {
  return ax.delete(`/event_series_meta/${id}`);
};
