import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ContentContainer = ({ title, children }) => {
  const [t] = useTranslation();
  return (
    <div className="col-md-12">
      <Link to={`/dashboard`} className="small">
        &laquo; {t("back_to_dashboard")}
      </Link>
      <h1>{title}</h1>
      {children}
    </div>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};
