import React from "react";
import { useTranslation } from "react-i18next";
import appConfig from "../../appConfig";
import CalendarList from "./CalendarList";
import { DashboardElement } from "./DashboardElement";
import { FilteredEventList } from "./FilteredEventList";

function NaaDashboardIndex() {
  const [t] = useTranslation();

  return (
    <div className="col-lg-12 cold-md-12">
      <h1>Übersicht</h1>
      <div className="row dashboard">
        {!appConfig.appointment_quick_accept && (
          <DashboardElement
            icon="calendar-plus"
            title={t("appointments.requests")}
          >
            <FilteredEventList params={{ status: "requested" }} />
          </DashboardElement>
        )}
        <DashboardElement
          icon="calendar-check"
          title={t("appointments.accepted")}
        >
          <FilteredEventList params={{ status: "accepted" }} />
        </DashboardElement>
        <CalendarList />
      </div>
    </div>
  );
}

export default NaaDashboardIndex;
