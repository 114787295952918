import {
  REMOTE_EVENT_STATUS_CHANGE,
  UPDATE_CALENDARS,
  UPDATE_CALENDAR_DETAILS,
  UPDATE_CURRENT_USER,
} from "../actions/dashboard";

const INITIAL_STATE = {
  loading: true,
  requestedCalendars: [],
  calendars: [],
  declinedEvents: [],
  currentUser: null,
};

export const dashboardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UPDATE_CALENDARS:
      return {
        ...state,
        calendars: action.calendars,
      };
    case UPDATE_CALENDAR_DETAILS:
      const newState = {
        ...state,
      };
      for (let index = 0; index < newState.calendars.length; index++) {
        const cal = newState.calendars[index];
        if (cal.id === action.id) {
          cal.attributes.title = action.details.title;
          cal.attributes.description = action.details.description;
          break;
        }
      }
      return newState;
    default:
      return state;
  }
};
