import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export const ActionButton = ({ onClick, desc, title, icon, disabled }) => {
  const [t] = useTranslation();
  return (
    <>
      <Button disabled={disabled} variant="primary" onClick={onClick}>
        {icon && <i className={`fa fa-${icon}`}></i>}
        {t(title)}
      </Button>
      {desc && <p className="small">{t(desc)}</p>}
    </>
  );
};

ActionButton.defaultProps = { icon: "", title: "" };

ActionButton.propTypes = {
  desc: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
