import React, { useState } from "react";
import { initializePasswordReset } from "../../api/user";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationLayer } from "../common/NotificationLayer";
import { CommonSpinner } from "../common/CommonSpinner";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import appConfig from "../../appConfig";

function ForgotPassword() {
  const [email, setEmail] = useState(appConfig.debug ? "@it-ref.de" : "");
  const [message, setMessage] = useState(null);
  const [className, setClassName] = useState("");
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const submitForm = () => {
    setLoading(true);
    setMessage("");
    initializePasswordReset(email)
      .then((res) => {
        setLoading(false);
        setMessage("forgot_password.flash_email_send");
        setClassName("success");
      })
      .catch((e) => {
        setLoading(false);
        if (e.request.status === 400) {
          setMessage(e.response.data.message);
        } else if (e.request.status === 409) {
          setMessage("forgot_password.flash_already_requested");
          setClassName("info");
        } else {
          setMessage("forgot_password.flash_wrong_email_or_password");
          setClassName("danger");
        }
      });
  };

  useEffect(() => {
    setMessage("");
  }, [email]);

  return (
    <div className="col-md-8">
      <h1>{t("forgot_password.title")}</h1>
      <div>
        <p>{t("forgot_password.introduction_text")}</p>
      </div>
      <div className="overlay-content">
        <form className="simple_form new_ansb_user">
          <div className="input email optional ansb_user_email form-group">
            <label className="email optional input-head">E-Mail</label>
            <input
              className="string email optional form-control"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="ansb_user[email]"
              id="ansb_user_email"
            />
          </div>

          <CommonSpinner loading={loading} />

          {className !== "success" && (
            <Button
              onClick={(e) => {
                e.preventDefault(), submitForm();
              }}
              type="submit"
              name="commit"
              className="btn button ghost-button overlay-button"
            >
              {t("forgot_password.button_password_reset")}
            </Button>
          )}
        </form>
        <NotificationLayer
          customTrigger={message}
          message={t(message)}
          className={className}
        />
        <Link to="/">{t("forgot_password.button_to_login")}</Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
