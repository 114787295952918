import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
  hideConfirmationModal,
  showConfirmationModal,
} from "../../actions/app";
import {
  createEventSeries,
  updateEventSeries,
  deleteEventSeries,
} from "../../api/event_series_meta";
import appConfig from "../../appConfig";
import { store } from "../../store/store";
import { CommonForm } from "../common/CommonForm";
import { DeleteButton } from "../common/DeleteButton";
import { EventSeriesFormFields } from "../forms/EventSeriesFormFields";

export const EventSeriesEditor = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const { currentEventSeries } = useSelector((state) => state.appState);

  const createInitialValues = () => {
    const now = new Date();
    now.setMinutes(Math.round(now.getMinutes() / 5) * 5);
    now.setSeconds(0, 0);

    const initialValues = {
      title: "",
      repetition: "weekly",
      runtime_start: now,
      runtime_end: new Date(now).setMonth(now.getMonth() + 1),
      start_hour: now.getHours(),
      start_minute: "00",
      duration: appConfig.defaultAppointmentDuration,
      selections: [],
    };
    return initialValues;
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(
      t("validation.field_required", { field: t("title") })
    ),
    calendar_id: Yup.number().required(t("validation.select_calendar")),
    selections: Yup.array().required(t("validation.select_days")),
  });

  const calendarPath = () => {
    if (window.location.pathname.includes("calendar")) return true;
  };

  const onSubmit = (values) => {
    const sendValues = { ...values };
    // send timestamp in seconds instead of milliseconds used in JS
    sendValues.runtime_end /= 1000;
    sendValues.runtime_start /= 1000;

    const resPromise = new Promise((resolve, reject) => {
      if (currentEventSeries?.id) {
        sendValues.id = currentEventSeries.id;
        updateEventSeries(sendValues)
          .then((response) => {
            resolve(response);
            if (!calendarPath()) {
              history.push(`/event_series/${sendValues.id}`);
            }
          })
          .catch(reject);
      } else {
        createEventSeries(sendValues)
          .then((response) => {
            resolve(response);
            if (!calendarPath()) {
              history.push(`/event_series/${response.data.id}`);
            }
          })
          .catch(reject);
      }
    });
    return resPromise;
  };

  const confirmDelete = () => {
    store.dispatch(
      showConfirmationModal({
        title: t("confirmation_required"),
        description: t("event.series.really_delete_desc"),
        proceed: confirmCallback,
      })
    );
  };

  const confirmCallback = (confirmed) => {
    if (confirmed) {
      deleteEventSeries(currentEventSeries.id)
        .then(() => {
          history.push("/event_series");
          store.dispatch(hideConfirmationModal());
        })
        .catch(console.error);
    } else {
      store.dispatch(hideConfirmationModal());
    }
  };

  return (
    <div className="event-series-editor clearfix">
      <CommonForm
        validationSchema={validationSchema}
        initialValues={currentEventSeries || createInitialValues()}
        apiEndpoint={onSubmit}
        fieldsComponent={EventSeriesFormFields}
        translationScope="event.series"
      />
      <hr />
      {currentEventSeries?.id && <DeleteButton onClick={confirmDelete} />}
    </div>
  );
};
