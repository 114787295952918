import { useTranslation } from "react-i18next";
import { LabeledRow } from "../common/LabeledRow";
import { EventStartEnd } from "./EventStartEnd";

const dateOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const EventTimes = ({ start, end }) => {
  const [t] = useTranslation();

  return (
    <div>
      <LabeledRow label={t("date")}>
        <EventStartEnd start={start} end={end} />
      </LabeledRow>
    </div>
  );
};
