import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

/**
 * Abstract away head clutter around dashboard content
 * @param {string} title | header text
 * @param {string} icon
 * @param {string} description | when set, displays a tooltip
 * @param {string} boxColor
 * @returns dashboard element component
 */
export const DashboardElement = ({
  title,
  icon,
  children,
  boxColor,
  description,
}) => {
  const popover = (
    <Popover id="description-popover">
      <Popover.Content>{description}</Popover.Content>
    </Popover>
  );

  const colorClass = "box-" + boxColor;

  return (
    <div className="col-lg-4">
      <div className="ie-flex-fix">
        <div className={`${colorClass} dashboard-element`}>
          <div className="dashboard-head">
            <h4 className="float-left">
              <i className={`fas fa-${icon} far-${icon}`}></i>
              &nbsp; {title}
            </h4>
            {description && (
              <OverlayTrigger
                transition={false}
                placement="top"
                overlay={popover}
              >
                <button className="float-right btn-link">
                  <i className="fas fa-question-circle"></i>
                </button>
              </OverlayTrigger>
            )}
          </div>
          <div className={`row dashboard-content ${colorClass}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardElement.defaultProps = {
  boxColor: "blue",
};
DashboardElement.propTypes = {
  boxColor: PropTypes.string,
  children: PropTypes.any,
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};
