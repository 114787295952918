import React from "react";
import { LabeledRow } from "../common/LabeledRow";

function TenantMailerFormFields({ formik }) {
  const inputElement = (field) => {
    const props = {
      name: field,
      type: "string",
      className: "form-control",
      onChange: formik.handleChange,
      defaultValue: formik.values[field],
    };

    return (
      <LabeledRow label={field} key={field}>
        <textarea {...props} rows="8"></textarea>
      </LabeledRow>
    );
  };

  return window.ITREF.config.tenant_fields
    .filter((key) => key.startsWith("mailer_"))
    .map(inputElement);
}

export default TenantMailerFormFields;
