import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { checkForUserInSession, logOut } from "../api/user";
import Profil from "../components/Profil";
import { useCurrentUser } from "../custom_hooks/user";
import ForgotPassword from "./login/ForgotPassword";
import LoginForm from "./login/LoginForm";
import CalendarIndex from "./calendar/CalendarIndex";
import CalendarShow from "./calendar/CalendarShow";
import Dashboard from "./Dashboard";
import TtkIndex from "./htk/TtkIndex";
import MemberMap from "./MemberMap";
import { EventSeriesIndex } from "./event_series/EventSeriesIndex";
import MemberOverview from "./MemberOverview";
import { ProtectedComponent } from "./ProtectedComponent";
import { SignupForm } from "./SignupForm";
import { ContentContainer } from "./common/ContentContainer";
import FooterNavigation from "./FooterNavigation";
import { UserRole } from "../constants";

function Routing() {
  const currentUser = useCurrentUser();
  const location = useLocation;

  useEffect(() => {
    checkForUserInSession()
      .then((res) => {
        if (res.data.data.attributes.user_role == UserRole.WAA) {
          logOut();
        }
      })
      .catch((e) => {
        //console.log("logged out");
      });
  }, []);
  return (
    <>
      <Route exact path="/member_map">
        <MemberMap />
      </Route>
      {location().pathname != "/member_map" && (
        <div className="page-header one-column">
          <div className="page-content">
            <div className="content-wrap">
              <div className="container">
                <div className="content-area">
                  <Switch>
                    <Route exact path="/dashboard">
                      <ProtectedComponent>
                        <Dashboard />
                      </ProtectedComponent>
                    </Route>
                    <Route exact path="/calendars/:id/edit">
                      <ProtectedComponent>
                        <CalendarIndex />
                      </ProtectedComponent>
                    </Route>
                    <Route exact path="/calendars/create">
                      <ProtectedComponent>
                        <CalendarIndex />
                      </ProtectedComponent>
                    </Route>
                    <Route exact path="/calendars">
                      <ProtectedComponent>
                        <CalendarIndex />
                      </ProtectedComponent>
                    </Route>
                    <Route path="/calendars/:id">
                      <ProtectedComponent>
                        <CalendarShow />
                      </ProtectedComponent>
                    </Route>
                    <Route path="/ttks">
                      <ProtectedComponent>
                        <TtkIndex />
                      </ProtectedComponent>
                    </Route>
                    <Route path="/download">
                      <ProtectedComponent>
                        <ContentContainer title="Download">
                          <p>Noch keine Dateien hinterlegt...</p>
                        </ContentContainer>
                      </ProtectedComponent>
                    </Route>
                    <Route path="/profil">
                      <ProtectedComponent>
                        <Profil />
                      </ProtectedComponent>
                    </Route>
                    <Route path="/event_series/:series_id">
                      <ProtectedComponent>
                        <EventSeriesIndex />
                      </ProtectedComponent>
                    </Route>
                    <Route exact path="/event_series">
                      <ProtectedComponent>
                        <EventSeriesIndex />
                      </ProtectedComponent>
                    </Route>
                    <Route path="/member_overview">
                      <ProtectedComponent>
                        <MemberOverview />
                      </ProtectedComponent>
                    </Route>
                    <Route exact path="/">
                      {!!currentUser ? <Dashboard /> : <LoginForm />}
                    </Route>
                    <Route exact path="/forgot_password">
                      <ForgotPassword />
                    </Route>
                    {!currentUser && (
                      <Route exact path="/sign_up">
                        <SignupForm />
                      </Route>
                    )}
                  </Switch>
                </div>
              </div>
            </div>
          </div>
          <FooterNavigation />
        </div>
      )}
    </>
  );
}

export default Routing;
