import React from "react";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";

export const CommonSpinner = ({ children, loading }) => (
  <div className="sweet-loading" style={{ textAlign: "center" }}>
    {children}
    <BeatLoader sizeUnit="px" size={8} loading={loading} />
  </div>
);

CommonSpinner.defaultProps = {
  loading: true,
};
CommonSpinner.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};
