import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export const DeleteButton = (props) => {
  const [t] = useTranslation();

  return (
    <Button variant="danger" {...props}>
      <i className="fas fa-trash status-indicator"></i>
      {t("delete")}
    </Button>
  );
};
