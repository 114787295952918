import React from "react";
import { useTranslation } from "react-i18next";
import { ACCESS_RIGHTS } from "../../constants";
import { useRights } from "../../custom_hooks/user";
import { hasRight } from "../../shared";
import AllEvents from "./AllEvents";
import CalendarList from "./CalendarList";
import CanceledEvents from "./CanceledEvents";
import NewAppointments from "./NewAppointments";
import NewMembers from "./NewMembers";
import PasswordReset from "./PasswordReset";
import SettingsElement from "./SettingsElement";

const TtkDashboardIndex = () => {
  const [t] = useTranslation();
  const rights = useRights();

  let views = [];

  if (hasRight(ACCESS_RIGHTS.calendar)) {
    views.push(<NewAppointments key="newAppointments" />);
    views.push(<CanceledEvents key="canceled" />);
  }

  if (hasRight(ACCESS_RIGHTS.confirm_users)) {
    views.push(<NewMembers key="nm" />);
    views.push(<PasswordReset key="passwordReset" />);
  }

  if (hasRight(ACCESS_RIGHTS.calendar)) {
    views.push(<CalendarList key="clist" />);
    views.push(<AllEvents key="clist" />);
  }

  return (
    <div className="row dashboard">
      {!rights.length ? (
        <div className="col-md-12">
          {!rights.length && <p>{t("dashboard.no_rights")}</p>}
        </div>
      ) : (
        <>
          {views}
          <SettingsElement />
        </>
      )}
    </div>
  );
};
export default TtkDashboardIndex;
