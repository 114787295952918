import React, { useState, useEffect } from "react";
import { useUsers } from "../custom_hooks/user";
import { ContentContainer } from "./common/ContentContainer";
import { attributeOrFallback } from "../shared";
import { useTranslation } from "react-i18next";
import { CommonSpinner } from "./common/CommonSpinner";
import { GeoTest } from "./common/GeoTest";
import MemberDeleteModal from "./member_overview/MemberDeleteModal";
import Button from "react-bootstrap/Button";
import { Table } from "react-bootstrap";

function MemberOverview() {
  const [lastUpdate, setLastUpdate] = useState(1);
  const [users, loadingUsers] = useUsers(lastUpdate, { user_role: "naa" });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [t] = useTranslation();

  const toggleDeleteModal = (id) => {
    setSelectedUserId(id);
    setShowModal(true);
  };

  const renderUsers = () => {
    const userContent = users.map((user) => {
      const { status, email, user_role, location } = user.attributes;
      const { id } = user;
      return (
        <tr key={user.id}>
          <td>{attributeOrFallback(user.attributes, "title")}</td>
          <td>{attributeOrFallback(user.attributes, "first_name")}</td>
          <td>{attributeOrFallback(user.attributes, "last_name")}</td>
          <td>{attributeOrFallback(location, "clinic_name")}</td>
          <td>{email}</td>
          <td>{attributeOrFallback(location, "phone")}</td>
          <td>{attributeOrFallback(location, "fax")}</td>
          <td>{attributeOrFallback(location, "postal_code")}</td>
          <td>{attributeOrFallback(location, "city")}</td>
          <td>{attributeOrFallback(location, "street")}</td>
          <td>
            <GeoTest geo_code={location.geo_code}>
              <i type="far" className="fa fa-eye"></i>
            </GeoTest>
          </td>
          <td>{status}</td>
          <td>{user_role}</td>
          <td>
            <Button variant="secondary" onClick={() => toggleDeleteModal(id)}>
              <i className="fa fa-trash"></i>
            </Button>
          </td>
        </tr>
      );
    });
    return userContent;
  };

  if (!users) return <CommonSpinner />;

  return (
    <ContentContainer title={t("member_map.title")}>
      <Table hover>
        <thead>
          <tr>
            <th>{t("user.title")}</th>
            <th>{t("user.first_name")}</th>
            <th>{t("user.last_name")}</th>
            <th>{t("location.clinic_name")}</th>
            <th>{t("location.email")}</th>
            <th>{t("location.phone")}</th>
            <th>{t("location.fax")}</th>
            <th>{t("location.postal_code")}</th>
            <th>{t("location.city")}</th>
            <th>{t("location.street")}</th>
            <th>{t("location.geo_code")}</th>
            <th>{t("user.status.title")}</th>
            <th>{t("user.user_role")}</th>
            <th></th>
          </tr>
        </thead>
        {users && !loadingUsers && <tbody>{renderUsers()}</tbody>}
      </Table>
      <MemberDeleteModal
        show={showModal}
        onHide={() => {
          setShowModal(false), setLastUpdate(Date.now());
        }}
        selectedUserId={selectedUserId}
      />
    </ContentContainer>
  );
}

export default MemberOverview;
