import React from "react";
import { useCurrentUser } from "../custom_hooks/user";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import appConfig from "../appConfig";

function PublicQuickLinks() {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();

  return (
    <>
      {!currentUser && (
        <div className="quick-links">
          <div className="meta-links">
            <div className="topbar">
              <ul className="vip-links">
                <li>
                  <Link to="/">
                    <span>
                      <i type="fas" className="fa fa-sign-in-alt"></i>
                    </span>
                    {t("login.login")}
                  </Link>
                </li>
                <li>
                  <Link to="/sign_up">
                    <span>
                      <i type="fas" className="fa fa-user-plus"></i>
                    </span>
                    {t("sign_up.header")}
                  </Link>
                </li>
                {appConfig.show_member_map && (
                  <li>
                    <Link to="/member_map">
                      <span>
                        <i type="fas" className="fas fa-map-marker-alt"></i>
                      </span>
                      {t("quick_links.member_map")}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublicQuickLinks;
