import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth, updateAuthCode } from "../../actions/app";
import {
  simpleDecryptPrivateKey,
  getPwFromLocalStorage,
} from "../../actions/decryption";
import { LabeledRow } from "./LabeledRow";
import { NotificationLayer } from "./NotificationLayer";
import appConfig from "../../appConfig";

const AuthCode = ({ children, title }) => {
  const [t] = useTranslation();
  const { authCode } = useSelector((state) => state.appState);
  const { currentUser } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(appConfig.debug ? "1Passwort!" : "");
  const [erro, setErro] = useState("");

  const onFormSubmit = (e) => {
    setErro("");
    if (e) e.preventDefault();
    if (auth === "") return;

    const privateKey = simpleDecryptPrivateKey(currentUser.private_key, auth);
    const password = getPwFromLocalStorage(auth);
    if (privateKey) {
      dispatch(updateAuth(auth, password));
    } else {
      setErro(t("wrong_auth"));
      dispatch(updateAuthCode(""));
    }
  };

  const onAuthUpdate = (event) => {
    setAuth(event.target.value);
    setErro("");
  };

  return !authCode ? (
    <div>
      <h4>{title}</h4>

      <div className="form-inputs">
        <form onSubmit={onFormSubmit}>
          <LabeledRow label={t("auth_code")}>
            <input
              type="password"
              name="auth"
              value={auth}
              onChange={onAuthUpdate}
              className="form-control"
            />
          </LabeledRow>
          <Button
            variant="primary"
            onClick={onFormSubmit}
            className="button-lg ghost-button grassgreen-button-invers"
          >
            {t("decrypt")}
          </Button>
        </form>
        <NotificationLayer
          customTrigger={erro}
          message={erro}
          className="danger"
        />
        <p className="small">{t("auth_code_form.verify_action")}</p>
      </div>
    </div>
  ) : (
    children
  );
};

AuthCode.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};

export default AuthCode;
