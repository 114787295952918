import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { signUp } from "../api/user";
import { LabeledRow } from "./common/LabeledRow";
import { CommonForm } from "./common/CommonForm";
import { SignupFormFields } from "./forms/SignupFormFields";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const SignupForm = () => {
  const [t] = useTranslation();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(
      t("validation.field_required", { field: t("user.first_name") })
    ),
    last_name: Yup.string().required(
      t("validation.field_required", { field: t("user.last_name") })
    ),
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.email")),
    clinic_name: Yup.string().required(
      t("validation.field_required", { field: t("location.clinic_name") })
    ),
    street: Yup.string().required(
      t("validation.field_required", { field: t("location.street") })
    ),
    postal_code: Yup.number()
      .typeError(
        t("validation.numbers_only", { field: t("location.postal_code") })
      )
      .required(
        t("validation.field_required", { field: t("location.postal_code") })
      ),
    city: Yup.string().required(
      t("validation.field_required", { field: t("location.city") })
    ),
    phone: Yup.number()
      .typeError(t("validation.numbers_only", { field: t("location.phone") }))
      .required(t("validation.field_required", { field: t("location.phone") })),
    fax: Yup.number().typeError(
      t("validation.numbers_only", { field: t("location.fax") })
    ),
  });

  var initialValues = { show_in_map: true, show_contact_details: true };

  return (
    <div className="col-md-8">
      <CommonForm
        apiEndpoint={signUp}
        translationScope="sign_up"
        validationSchema={validationSchema}
        fieldsComponent={SignupFormFields}
        initialValues={initialValues}
      >
        <Row>
          <Col sm={12} className="small">
            {t("validation.asterisks_optional")}
          </Col>
        </Row>
      </CommonForm>
    </div>
  );
};
