import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { store } from "../store/store";
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from "./Navigation";
import Routing from "./Routing";
import { CommonSpinner } from "./common/CommonSpinner";
import { EventModal } from "./common/EventModal";
import { CableHandler } from "./CableHandler";
import "./../i18n";
import ConfirmationModal from "./common/ConfirmationModal";
import CookieConsent from "./CookieConsent";

function Main() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense
          fallback={
            <div className="container">
              <CommonSpinner />
            </div>
          }
        >
          <CableHandler />
          <Navigation />
          <Routing />
          <ConfirmationModal />
          <EventModal />
          <CookieConsent />
        </Suspense>
      </Router>
    </Provider>
  );
}

export default Main;
