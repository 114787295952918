import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { createNewAppointment, updateAppointment } from "../../api/appointment";
import { NotificationLayer } from "../common/NotificationLayer";
import { LabeledRow } from "../common/LabeledRow";
import { DatePickerField } from "../FormikDatePicker";
import { EventStatus } from "../../eventStatus";
import { useTranslation } from "react-i18next";
import { CommonSpinner } from "../common/CommonSpinner";
import { FormikErrorList } from "../common/FormikErrorList";
import { EventBlockStatus } from "../events/EventBlockStatus";
import { blockEvent, getEvent, unblockEvent } from "../../api/event";
import { useCurrentUser } from "../../custom_hooks/user";
import {
  useCurrentAppointment,
  useCurrentEvent,
} from "../../custom_hooks/events";
import { useDispatch } from "react-redux";
import { updateCurrentEvent } from "../../actions/app";
import { EventStartEnd } from "../events/EventStartEnd";
import { remoteEventStatusChange } from "../../actions/dashboard";
import appConfig from "../../appConfig";

function Checkbox({ field, type, checked }) {
  return (
    <input
      className="boolean optional"
      {...field}
      type={type}
      checked={checked}
    />
  );
}

const SignupSchema = Yup.object().shape({
  salutation: Yup.string().required("Titel muss ausgefüllt werden."),
  firstName: Yup.string().required("Vorname muss ausgefüllt sein."),
  lastName: Yup.string().required("Nachname muss ausgefüllt werden."),
  birthDay: Yup.string().required("Geburtstag muss angegeben sein."),
  reachability: Yup.string().required("Eine Kontaktart muss ausgewählt sein."),
  email: Yup.string().when("reachability", {
    is: "email",
    then: Yup.string().required("Geben Sie eine E-Mail an."),
  }),
  phone: Yup.string().when("reachability", {
    is: "phone",
    then: Yup.string().required("Geben Sie eine Telefonnummer an."),
  }),
  insuranceType: Yup.string().required(
    "Versicherungsart muss ausgefüllt werden."
  ),
});

function DecryptedAppointment() {
  const [t] = useTranslation();
  const currentUser = useCurrentUser();
  const currentEvent = useCurrentEvent();
  const appointment = useCurrentAppointment();
  const [blockedNow, setBlockedNow] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentEvent) return;
    const editable = [EventStatus.CREATED, EventStatus.REQUESTED].includes(
      currentEvent.status
    );
    setFormDisabled(!editable);
  }, [currentEvent]);

  useEffect(() => {
    if (blockedNow) return () => unblockEvent(currentEvent.id);
  }, [blockedNow]);

  const handleSubmit = (values) => {
    setBlockedNow(false);
    setSending(true);

    if (currentEvent.status === EventStatus.CREATED) {
      createNewAppointment(values, currentEvent, currentUser)
        .then(() => {
          getEvent(currentEvent.id).then((res) => {
            dispatch(updateCurrentEvent(res.data.data.attributes));
          });
        })
        .catch(onComplete);
    } else {
      updateAppointment({ ...appointment, ...values })
        .then(onComplete)
        .catch(onComplete);
    }
  };

  const onComplete = (res) => {
    setSending(false);
    setApiResponse(res);
    dispatch(remoteEventStatusChange(res.data.data));
  };

  const onChangeHandler = () => {
    if (currentEvent.status !== EventStatus.CREATED || blockedNow) return;
    blockEvent(currentEvent.id)
      .then(() => setBlockedNow(true))
      .catch((e) => {
        // Blocking failed. Most likely this event was already blocked by this user, or blocked externally
      });
  };

  const submitButtonLabel = () => {
    switch (true) {
      case currentEvent.status === EventStatus.CREATED:
        if (appConfig.appointment_quick_accept) {
          return "appointments.book_date";
        } else {
          return "appointments.request_date";
        }
      default:
        return "appointments.save_changes";
    }
  };

  if (appointment) {
    if (!appointment.insuranceType) appointment.insuranceType = "legal";
    if (!appointment.salutation) appointment.salutation = "ms";
    return (
      <div className="show_appointment">
        <div className="form-horizontal">
          <p className="bold">
            <EventStartEnd start={currentEvent.start} end={currentEvent.end} />
          </p>
          <p>{t("event.enter_details_to_reserve")}</p>
          <hr />
          {currentEvent.status === EventStatus.CREATED && (
            <EventBlockStatus started={blockedNow} eventID={currentEvent.id} />
          )}
          <Formik
            enableReinitialize={true}
            initialValues={appointment}
            onSubmit={handleSubmit}
            validationSchema={SignupSchema}
          >
            {({ values, errors }) => (
              <Form className="simple_form new_appointment">
                <fieldset disabled={formDisabled || sending}>
                  <div className="form-inputs">
                    <LabeledRow label="Anrede">
                      <div className="radio-group">
                        <label>
                          <Field
                            type="radio"
                            name="salutation"
                            value="ms"
                            onBlur={onChangeHandler}
                          />
                          <span className="radio-label">Frau</span>
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="salutation"
                            value="mr"
                            onBlur={onChangeHandler}
                          />
                          <span className="radio-label">Herr</span>
                        </label>
                      </div>
                    </LabeledRow>

                    <LabeledRow label="Vorname">
                      <Field
                        name="firstName"
                        type="text"
                        onBlur={onChangeHandler}
                      ></Field>
                    </LabeledRow>

                    <LabeledRow label="Nachname">
                      <Field
                        name="lastName"
                        type="text"
                        onBlur={onChangeHandler}
                      ></Field>
                    </LabeledRow>

                    <LabeledRow label="Geburtsdatum">
                      <DatePickerField name="birthDay" />
                    </LabeledRow>

                    <LabeledRow label={t("appointments.patient_inform_method")}>
                      <div className="radio-group">
                        <label>
                          <Field
                            type="radio"
                            name="reachability"
                            value="email"
                          />
                          <span className="radio-label">{t("email")}</span>
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="reachability"
                            value="phone"
                          />
                          <span className="radio-label">{t("phone")}</span>
                        </label>
                      </div>
                    </LabeledRow>

                    <LabeledRow label={t("email")}>
                      <Field name="email" type="email" />
                    </LabeledRow>

                    <LabeledRow label={t("phone")}>
                      <Field name="phone" type="text" />
                    </LabeledRow>

                    <LabeledRow
                      label={t("appointments.patient_was_at_lmu_before")}
                      required={false}
                    >
                      <Field
                        name="lmuPatient"
                        type="checkbox"
                        checked={values.lmuPatient}
                        component={Checkbox}
                      />
                    </LabeledRow>

                    <LabeledRow label={t("insurance_type.label")}>
                      <Field
                        className="form-control select required"
                        component="select"
                        id="insuranceType"
                        name="insuranceType"
                      >
                        <option value="legal">
                          {t("insurance_type.legal")}
                        </option>
                        <option value="private_">
                          {t("insurance_type.private_")}
                        </option>
                        <option value="self">{t("insurance_type.self")}</option>
                      </Field>
                    </LabeledRow>

                    <LabeledRow label={t("diagnosis")} required={false}>
                      <Field name="suspicion" component="textarea"></Field>
                    </LabeledRow>

                    <LabeledRow label="Fragestellung/Auftrag" required={false}>
                      <Field name="question" component="textarea"></Field>
                    </LabeledRow>

                    <FormikErrorList errors={errors} />

                    <div className="form-actions">
                      {sending ? (
                        <CommonSpinner />
                      ) : (
                        <button
                          disabled={Object.keys(errors).length}
                          type="submit"
                          className="btn btn-default button-lg ghost-button grassgreen-button-invers"
                        >
                          {t(submitButtonLabel())}
                        </button>
                      )}
                    </div>
                  </div>
                  <NotificationLayer
                    apiResponse={apiResponse}
                    customTrigger={apiResponse}
                    message={t("appointments.update_success")}
                  />
                </fieldset>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <CommonSpinner />
      </div>
    );
  }
}

export default DecryptedAppointment;
