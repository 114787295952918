import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  hideConfirmationModal,
  showConfirmationModal,
  updateSelectedUser,
} from "../../actions/app";
import { slimPasswordReset } from "../../actions/decryption";
import { PasswordResetStatus } from "../../constants";
import { useCurrentUser, useUsers } from "../../custom_hooks/user";
import { ContextAwareToggle } from "../common/ContextAwareToggle";
import { DashboardElement } from "./DashboardElement";
import AuthCode from "../common/AuthCode";
import { CommonSpinner } from "../common/CommonSpinner";
import { denyPasswordReset, updatePasswordResetStatus } from "../../api/user";
import { store } from "../../store/store";

function PasswordReset() {
  const { SET_ON_DREAMIN_FORT, INACTIVE } = PasswordResetStatus;
  const dispatch = useDispatch();
  const { password } = useSelector((state) => state.appState);
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(1);
  const [users, loadingUsers] = useUsers(lastUpdate, {
    password_reset_status: SET_ON_DREAMIN_FORT,
  });
  const [t] = useTranslation();

  const denyLabel = t("htk_password_reset.deny.button");
  const confirmLabel = t("htk_password_reset.confirm.button");

  const openConfirmationModal = (user, action = "confirm") => {
    dispatch(updateSelectedUser(user));

    const translationScope = `htk_password_reset.${action}.`;
    const clickAction = action === "confirm" ? onConfirm : onDenial;
    const viewComponent = (
      <div className="rowflex space-between" style={{ marginTop: "12px" }}>
        <AuthCode>
          <Button variant="primary" onClick={clickAction}>
            {t(translationScope + "proceed")}
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            {t(translationScope + "cancel")}
          </Button>
        </AuthCode>
      </div>
    );

    dispatch(
      showConfirmationModal({
        title: t(translationScope + "title"),
        description: t(translationScope + "desc"),
        viewComponent,
      })
    );
  };

  const onDenial = () => {
    closeModal();
    const { id } = store.getState().appState.selectedUser;
    denyPasswordReset(id).then(updateList).catch(onError);
    setLoading(true);
  };

  const onConfirm = () => {
    closeModal();
    const { id } = store.getState().appState.selectedUser;
    slimPasswordReset(password, currentUser.private_key, id)
      .then(updateList)
      .catch(onError);
    setLoading(true);
  };

  const onError = (e) => {
    window.alert(e.message);
    updateList();
  };

  const updateList = () => {
    setLoading(false);
    setLastUpdate(Date.now());
  };

  const closeModal = () => {
    dispatch(hideConfirmationModal());
  };

  const userCard = (user, index) => {
    const {
      id,
      attributes: { email, first_name, last_name },
    } = user;
    const cardKey = "user" + (index + 1).toString();

    return (
      <Card key={id}>
        <Card.Header>
          <ContextAwareToggle eventKey={cardKey}>
            {last_name}, {first_name} ({email})
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={cardKey}>
          <Card.Body>
            {!loading && !loadingUsers ? (
              <div className="rowflex space-between">
                <Button
                  onClick={() => {
                    openConfirmationModal(user, "confirm");
                  }}
                >
                  <i
                    title={confirmLabel}
                    type="far"
                    className="fa fa-check-circle"
                  ></i>
                  {confirmLabel}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    openConfirmationModal(user, "deny");
                  }}
                >
                  <i title={denyLabel} type="far" className="fa fa-ban"></i>
                  {denyLabel}
                </Button>
              </div>
            ) : (
              <CommonSpinner />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  return (
    <DashboardElement
      icon="key"
      title={t("htk_password_reset.title")}
      description={t("htk_password_reset.desc")}
    >
      <Accordion>{users.map(userCard)}</Accordion>
      {!loadingUsers && users.length < 1 && (
        <p>{t("htk_password_reset.list_empty")}</p>
      )}
    </DashboardElement>
  );
}

export default PasswordReset;
