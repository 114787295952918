import { useTranslation } from "react-i18next";
import { DashboardElement } from "./DashboardElement";
import { hasRight } from "../../shared";
import { ACCESS_RIGHTS } from "../../constants";
import { DashboardButton } from "./DashboardButton";

const SettingsElement = () => {
  const [t] = useTranslation();

  return (
    <DashboardElement title={t("settings")} boxColor="grey">
      {(hasRight(ACCESS_RIGHTS.user_overview) ||
        hasRight(ACCESS_RIGHTS.confirm_users)) && (
        <DashboardButton
          title={t("dashboard.buttons.member_overview")}
          href="/member_overview"
          icon="user-md"
        />
      )}
      {hasRight(ACCESS_RIGHTS.invite_ttks) && (
        <DashboardButton
          title={t("dashboard.buttons.permission")}
          href="/ttks"
          icon="hospital-user"
        />
      )}
      {hasRight(ACCESS_RIGHTS.calendar) && (
        <DashboardButton
          title={t("dashboard.buttons.event_series")}
          href="/event_series"
          icon="calendar-alt"
        />
      )}
      {hasRight(ACCESS_RIGHTS.calendar) && (
        <DashboardButton
          key="calendar-index"
          title={t("dashboard.buttons.calendars")}
          href="/calendars"
          icon="calendar"
        />
      )}
    </DashboardElement>
  );
};

export default SettingsElement;
