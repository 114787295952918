import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import MemberDeleteModal from "../member_overview/MemberDeleteModal";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { getTtks, updateRight } from "../../api/user";
import { getRights } from "../../api/right";
import Checkbox from "../Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateTtks } from "../../actions/app";
import { ContentContainer } from "../common/ContentContainer";
import { ACCESS_RIGHTS, UserRole } from "../../constants";
import { hasRight } from "../../shared";
import { InviteForm } from "./InviteForm";
import { useCurrentUser, useUserRole } from "../../custom_hooks/user";

function TtkIndex() {
  const rights = getRights();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { ttks } = useSelector((state) => state.appState);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const role = useUserRole();
  const currentUser = useCurrentUser();

  const toggleDeleteModal = (id) => {
    setSelectedUserId(id);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const url = useLocation();

  const refreshData = () => {
    getTtks().then((users) => {
      dispatch(updateTtks(users["data"].data));
    });
  };

  const updateRights = async (id, availableRight) => {
    updateRight(id, availableRight).then(refreshData);
  };

  const editable = hasRight(ACCESS_RIGHTS.invite_ttks);

  const userRow = ({ id, attributes, update = true }) => {
    const {
      email,
      first_name,
      last_name,
      status,
      user_role,
      rights: userRights,
    } = attributes;
    return (
      <tr key={"user-" + id}>
        <td>{email}</td>
        <td>{first_name}</td>
        <td>{last_name}</td>
        <td>{t(`user.status.${status}`)}</td>
        <td>{user_role}</td>
        {rights.map(({ attributes }) => {
          const { name } = attributes;
          const checked = !!userRights.find((myRight) => myRight === name);
          return (
            <td key={`checkbox-${id}-${name}`} className="centered">
              <Checkbox
                name={name}
                checked={checked}
                disabled={!editable || id === currentUser.id}
                onChange={() => {
                  if (update) updateRights(id, name);
                }}
              />
            </td>
          );
        })}
        {role === UserRole.HTK && (
          <td>
            <Button variant="secondary" onClick={() => toggleDeleteModal(id)}>
              <i className="fa fa-trash"></i>
            </Button>
          </td>
        )}
      </tr>
    );
  };

  return (
    <ContentContainer title={t("ttk_overview")}>
      <div className="row">
        <Switch>
          <Route exact path="/ttks/invite">
            <div className="col-md-2">
              <Link to="/ttks">&laquo; {t("back_to_overview")}</Link>
            </div>
            <div className="col-md-10">
              <InviteForm />
            </div>
          </Route>
          <Route exact path="/ttks">
            <div className="col-md-2">
              {editable && (
                <div className="margin-bottom">
                  <Link to="/ttks/invite">
                    <Button
                      variant="primary"
                      disabled={url.pathname === "/ttks/invite"}
                    >
                      <i className={`fa fa-plus`}></i>
                      {t("invite_ttk")}
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            {ttks && rights && (
              <div className="col-md-10">
                <h4>{t("rights.ttk")}</h4>
                <Table hover>
                  <thead>
                    <tr>
                      <th>{t("email")}</th>
                      <th>{t("first_name")}</th>
                      <th>{t("last_name")}</th>
                      <th>{t("rights.status")}</th>
                      <th>{t("rights.user_role")}</th>
                      {rights.map(({ id, attributes }) => (
                        <th key={id}>{t(`rights.${attributes.name}`)}</th>
                      ))}
                      {role === UserRole.HTK && <th>{t("delete")}</th>}
                    </tr>
                  </thead>
                  <tbody>{ttks.map(userRow)}</tbody>
                </Table>
              </div>
            )}
          </Route>
        </Switch>
      </div>
      <MemberDeleteModal
        show={!!selectedUserId}
        onHide={() => {
          setSelectedUserId(null), refreshData();
        }}
        selectedUserId={selectedUserId}
      />
    </ContentContainer>
  );
}

export default TtkIndex;
