import React from "react";
import { LabeledRow } from "../common/LabeledRow";

function CreateTenantForm({ formik }) {
  return (
    <>
      <LabeledRow label="Name">
        <input
          name="title"
          type="string"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.title}
        />
      </LabeledRow>
      <LabeledRow label="Domain">
        <input
          name="domain"
          type="string"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.domain}
        />
      </LabeledRow>
      <LabeledRow label="Subdomain">
        <input
          name="subdomain"
          type="string"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.subdomain}
        />
      </LabeledRow>
    </>
  );
}

export default CreateTenantForm;
