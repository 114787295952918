import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { updateCurrentUser } from "../../actions/dashboard";
import { logOut } from "../../api/user";
import { useCurrentUser } from "../../custom_hooks/user";
import { store } from "../../store/store";

const UserQuickLinks = () => {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();

  const handleLogoutButton = () => {
    store.dispatch(updateCurrentUser());
    logOut();
  };

  if (!currentUser) return null;

  return (
    <div className="quick-links meta-links topbar">
      <ul className="list-inline top-nav hlist-unstyled list-menu">
        <li className="dropdown non-after">
          <button
            className="btn btn-default btn-sm dropdown-toggle"
            data-toggle="dropdown"
            type="button"
          >
            {currentUser.email}
          </button>
          <ul className="dropdown-menu list-unstyled list-menu">
            <li className="menu">
              <Link to="/dashboard">
                <span>
                  <i type="fas" className="fa fa-tachometer-alt dark-green"></i>
                </span>
                {t("dashboard.title")}
              </Link>
              <Link to="/profil">
                <span>
                  <i type="fas" className="fa fa-user dark-green"></i>
                </span>
                {t("profil")}
              </Link>
              {/* <Link to="/download">
                <span>
                  <i
                    type="fas"
                    className="fa fa-cloud-download-alt dark-green"
                  ></i>
                  {t("download")}
                </span>
              </Link> */}
              <a
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault(), handleLogoutButton();
                }}
              >
                <span>
                  <i type="fas" className="fa fa-sign-out-alt dark-green"></i>
                </span>
                {t("login.logout")}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default UserQuickLinks;
