import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CommonSpinner } from "./common/CommonSpinner";

export const ProtectedComponent = ({ children }) => {
  const { currentUser, loading } = useSelector((state) => state.dashboard);
  const loggedIn = !!currentUser;
  const [t] = useTranslation();

  if (loggedIn) {
    return children;
  } else if (loading) {
    return <CommonSpinner />;
  } else {
    return <Link to="/">{t("login_to_view_this_page")}</Link>;
  }
};
