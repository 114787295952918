import React, { useState } from "react";
import { logIn } from "../../api/user";
import { useDispatch } from "react-redux";
import { updateAuth, updatePassword } from "../../actions/app";
import { NotificationLayer } from "../common/NotificationLayer";
import { Link } from "react-router-dom";
import { LabeledRow } from "../common/LabeledRow";
import { useTranslation } from "react-i18next";
import { PasswordResetStatus } from "../../constants";
import { updateCurrentUser } from "../../actions/dashboard";
import { setCookie, verifyCredentials } from "../../shared";
import { storeAuthInLocalStorage } from "../../actions/encryption";
import appConfig from "../../appConfig";

function LoginForm() {
  const { debug } = appConfig;
  const [email, setEmail] = useState(debug ? "@it-ref.de" : "");
  const [password, setPassword] = useState(debug ? "1Passwort!" : "");
  const [authentication, setAuthentication] = useState(
    debug ? "1Passwort!" : ""
  );
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const inPwReset = (user) =>
    user.password_reset_status === PasswordResetStatus.SET_ON_DREAMIN_FORT;

  const submitForm = () => {
    setError(false);
    setErrorMessage("");

    logIn({ email, password })
      .then((user) => {
        if (inPwReset(user)) {
          setErrorMessage(t("login.pw_reset"));
          setError(true);
          return;
        }
        verifyCredentials(user, password, authentication)
          .then(() => {
            setCookie("inSession", true);

            storeAuthInLocalStorage(password, authentication);
            dispatch(updateAuth(authentication, password));
            dispatch(updateCurrentUser(user));
          })
          .catch(onError);
      })
      .catch(onError);
  };

  const onError = (e) => {
    setErrorMessage(t("login.standard_error"));
    setError(true);
  };

  return (
    <div className="col-md-8">
      <h1>{t("login.member_area")}</h1>
      <h2>{t("login.login_noun")}</h2>
      <p>{t("login.introduction_text")}</p>
      <NotificationLayer
        customTrigger={error}
        message={errorMessage}
        className="danger"
      />
      <form>
        <LabeledRow label={t("email")} htmlFor="ansb_user_email">
          <input
            className="string email optional form-control"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            name="ansb_user[email]"
            id="ansb_user_email"
            autoComplete="username"
          />
        </LabeledRow>
        <LabeledRow label={t("password")} htmlFor="ansb_user_password">
          <input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="password optional form-control"
            type="password"
            autoComplete="current-password"
            id="ansb_user_password"
          />
        </LabeledRow>
        <LabeledRow label={t("auth_code")} htmlFor="ansb_user_auth">
          <input
            className="password optional form-control"
            type="password"
            autoComplete="one-time-code"
            id="ansb_user_auth"
            value={authentication}
            onChange={(e) => {
              setAuthentication(e.target.value);
            }}
          />
        </LabeledRow>
        <input
          onClick={(e) => {
            e.preventDefault(), submitForm();
          }}
          type="submit"
          name="commit"
          value="Anmelden"
          className="btn button-lg ghost-button overlay-button"
          data-disable-with="Anmelden"
        />
      </form>
      <div className="row form-group margin-bottom">
        <Link to={"/forgot_password"}>{t("login.forgot_password")}</Link>
      </div>
      <div className="row form-group margin-top-24">
        <h2>{t("sign_up.header")}</h2>
        {t("login.sign_up_desc")}&nbsp;
        <Link to={"/sign_up"}>{t("login.sign_up_action")}</Link>
      </div>
    </div>
  );
}

export default LoginForm;
