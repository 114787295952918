import { getCalendarName } from "../../shared";

export const CalendarTitle = ({ title, id, count = 0 }) => {
  return (
    <p>
      <span>
        <i type="fas" className="fa fa-calendar-alt"></i>
      </span>
      &nbsp;
      {title || getCalendarName(id)}
      {!!count && <span>&nbsp;({count})</span>}
    </p>
  );
};
