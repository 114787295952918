import React, { useEffect, useState } from "react";
import { getTenant } from "../../api/tenant";

import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { LabeledRow } from "../common/LabeledRow";
import { CommonSpinner } from "../common/CommonSpinner";

export const TenantShow = () => {
  const [t] = useTranslation();
  const [currentTenant, setCurrentTenant] = useState(null);
  const [logo, setLogo] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    loadTenant();
  }, [id]);

  useEffect(() => {
    loadTenant();
  }, []);

  const loadTenant = () => {
    if (id) {
      getTenant(id).then((res) => {
        setCurrentTenant(res.data.tenant);
        setLogo(res.data.logo);
      });
    }
  };
  if (!id || !currentTenant) return <CommonSpinner />;

  return (
    <div>
      <div className="float-right margin-bottom">
        <Link to={`/tenants/${id}/edit`}>
          <Button variant="primary">
            <i className={`fa fa-edit`}></i>
            {t("edit")}
          </Button>
        </Link>
      </div>
      <h1>{currentTenant.title}</h1>
      {currentTenant &&
        window.ITREF.config.tenant_fields.map((field) => {
          return (
            <LabeledRow label={field} key={field}>
              <div className="tenant-field-value">
                {!!currentTenant[field] && currentTenant[field].toString()}
              </div>
            </LabeledRow>
          );
        })}
      <Image src={logo} />
    </div>
  );
};
