import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CommonForm } from "../common/CommonForm";
import { CommonSpinner } from "../common/CommonSpinner";
import { getTenant, updateTenant } from "../../api/tenant";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TenantFormFields from "../forms/TenantFormFields";
import { useTranslation } from "react-i18next";
import TenantMailerFormFields from "../forms/TenantMailerFormFields";
import { Link } from "react-router-dom";

export const TenantEditor = () => {
  const [currentTenant, setCurrentTenant] = useState(null);
  const { id } = useParams();
  const [t] = useTranslation();

  useEffect(() => {
    loadTenant();
  }, [id]);

  useEffect(() => {
    loadTenant();
  }, []);

  const loadTenant = () => {
    if (id) {
      getTenant(id).then((res) => {
        setCurrentTenant(res.data.tenant);
      });
    }
  };

  const onSubmit = (values) => {
    var formData = new FormData();
    formData.append("id", id);
    for (var key in values) {
      formData.append(key, values[key]);
    }
    if (values.logo?.files?.length) {
      formData.append("logo", values.logo.files[0]);
    }
    return updateTenant(formData);
  };

  if (!currentTenant) {
    return <CommonSpinner />;
  }

  return (
    <Row>
      <Col sm={12}>
        <div className="float-right margin-bottom">
          <Link to={`/tenants/${currentTenant.id}`}>
            <Button variant="secondary">
              <i className={`fa fa-times`}></i>
              {t("cancel")}
            </Button>
          </Link>
        </div>
        <Tabs
          defaultActiveKey="common_fields"
          id="common_fields_tabs"
          className="mb-3"
          transition={false}
        >
          <Tab eventKey="common_fields" title="Standard-Einstellungen">
            <CommonForm
              encType="multipart/form-data"
              apiEndpoint={onSubmit}
              fieldsComponent={TenantFormFields}
              initialValues={currentTenant}
              translationScope="tenant.edit"
              resultOnly={false}
            ></CommonForm>
          </Tab>
          <Tab eventKey="mailers" title="Textvorlagen für E-Mails">
            <CommonForm
              encType="multipart/form-data"
              apiEndpoint={onSubmit}
              fieldsComponent={TenantMailerFormFields}
              initialValues={currentTenant}
              translationScope="tenant.edit_mailer"
              resultOnly={false}
            ></CommonForm>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};
