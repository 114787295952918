import React, { useEffect, useState } from "react";
import {
  displayDay,
  displayDateMonthYear,
  displayDate,
} from "../../dateFunctions";
import { getDayByIndex } from "../../shared";
import { useTranslation } from "react-i18next";
import { RuntimeDisplay } from "../events/RuntimeDisplay";
import { LabeledRow } from "../common/LabeledRow";

export const EventSeriesDetailRows = ({
  values,
  startTimeDate,
  endTimeDate,
}) => {
  const [t] = useTranslation();
  const runtimeEnd = displayDate(values.runtime_end, {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const monthlyDays = values.selections
    .map((dayIndex) => (dayIndex + 1).toString())
    .join(", ");

  return (
    <>
      <LabeledRow label={t("title")}>{values.title}</LabeledRow>
      <LabeledRow label={t("event.timeframe")}>
        <RuntimeDisplay
          startTimeDate={startTimeDate}
          endTimeDate={endTimeDate}
          duration={values.duration}
        />
      </LabeledRow>
      <LabeledRow label={t("event.series.repetition")}>
        {values.repetition === "weekly" ? (
          <div>
            {values.selections.map(getDayByIndex).join(", ")}
            <br />
            {t("event.series.weekly_repetition_desc", { end: runtimeEnd })}
          </div>
        ) : (
          <div>
            {t("event.series.monthly_repetition_desc", {
              days: monthlyDays,
              end: runtimeEnd,
            })}
          </div>
        )}
      </LabeledRow>
    </>
  );
};
