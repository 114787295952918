import React from "react";
import { useCurrentUser } from "../../custom_hooks/user";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CommonSpinner } from "../common/CommonSpinner";
import { logOut, updateUser } from "../../api/user";
import { CommonForm } from "../common/CommonForm";
import { UserFormFields } from "../forms/UserFormFields";
import { EmailFormFields } from "../forms/EmailFormFields";
import { updateCurrentUser } from "../../actions/dashboard";
import { store } from "../../store/store";
import { storableUser } from "../../shared";

function UpdateProfil() {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();

  const changeEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.field_required")),
    emailConfirmation: Yup.string()
      .email(t("validation.email"))
      .required(
        t("validation.field_required", {
          field: t("update_profil.email_confirmation"),
        })
      )
      .oneOf([Yup.ref("email"), null], t("validation.email_confirmation")),
  });
  const changeProfilSchema = Yup.object().shape({
    first_name: Yup.string().required(
      t("validation.field_required", { field: t("update_profil.first_name") })
    ),
    last_name: Yup.string().required(
      t("validation.field_required", { field: t("update_profil.last_name") })
    ),
  });

  const submitChange = (values) => {
    return new Promise((resolve, reject) => {
      updateUser(values, currentUser.id)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(updateCurrentUser(storableUser(response)));
          }
          resolve(response);
        })
        .catch(reject);
    });
  };

  const updateEmail = (values) => {
    return new Promise((resolve, reject) => {
      updateUser(values, currentUser.id).then(logOut).catch(reject);
    });
  };

  if (!currentUser) return <CommonSpinner />;

  const { email, title, first_name, last_name } = currentUser;

  return (
    <div>
      <div>
        <CommonForm
          initialValues={{ title, first_name, last_name }}
          validationSchema={changeProfilSchema}
          apiEndpoint={submitChange}
          translationScope="update_profil.names"
          fieldsComponent={NameFormFields}
          resultOnly={false}
        />
      </div>
      <div className="clearfix">
        <CommonForm
          translationScope="update_profil.email"
          initialValues={{ email, emailConfirmation: "" }}
          validationSchema={changeEmailSchema}
          apiEndpoint={updateEmail}
          fieldsComponent={EmailFormFields}
          resultOnly={false}
        />
      </div>
    </div>
  );
}

const NameFormFields = ({ formik }) => {
  return <UserFormFields formik={formik} withEmail={false} />;
};

export default UpdateProfil;
