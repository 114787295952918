import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteCalendar,
  getCalendar,
  getCalendars,
  permitUsers,
  updateCalendar,
} from "../../api/calendar";
import { reEncryptAppointments } from "../../api/appointment";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useHistory } from "react-router-dom";
import { NotificationLayer } from "../common/NotificationLayer";
import { store } from "../../store/store";
import {
  hideConfirmationModal,
  showConfirmationModal,
} from "../../actions/app";
import { DeleteButton } from "../common/DeleteButton";
import { LabeledRow } from "../common/LabeledRow";
import AuthCode from "../common/AuthCode";
import { CalendarPermissions } from "./CalendarPermissions";
import { useDispatch } from "react-redux";
import { ACCESS_RIGHTS, UserRole } from "../../constants";
import { updateCalendarDetails } from "../../actions/dashboard";
import { useUserRole } from "../../custom_hooks/user";
import { CalendarLink } from "../common/CalendarLink";
import { hasRight } from "../../shared";

export const CalendarEdit = ({ id }) => {
  const [calendar, setCalendar] = useState(null);
  const [userListChanged, setUserListChanged] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [autoDeleteEventsPrior, setAutoDeleteEventsPrior] = useState();
  const [t] = useTranslation();
  const [apiResponse, setApiResponse] = useState(null);
  const dispatch = useDispatch();
  const [newPermissionedUsers, setNewPermissionedUsers] = useState(null);
  const [promises, setPromises] = useState([]);
  const [promisesComplete, setPromisesComplete] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  const role = useUserRole();
  const eventListenerRef = useRef();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      getCalendar(id).then((response) => {
        const { attributes } = response.data.data;
        setCalendar(response.data.data);
        setTitle(attributes.title);
        setDescription(attributes.description || "");
        setNewPermissionedUsers(attributes.permissioned_users);
        setAutoDeleteEventsPrior(attributes.auto_delete_events_prior);
        setUserListChanged(false);
        setApiResponse(null);
        setProgressMessage("");
      });
    }
  }, [id]);

  useEffect(() => {
    if (!progressMessage) {
      eventListenerRef.current = null;
      return;
    }

    eventListenerRef.current = (event) => {
      const continueOK = window.confirm(
        t("calendar.updating.beforeunload_confirmation")
      );
      if (continueOK) return true;
      const returnValue = event.preventDefault();
      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === "string") {
        return (event.returnValue = returnValue);
      }
      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        return (event.returnValue = "");
      }
    };
  }, [progressMessage]);

  useEffect(() => {
    const eventListener = (event) => eventListenerRef.current(event);
    window.addEventListener("beforeunload", eventListener);
    return () => {
      window.removeEventListener("beforeunload", eventListener);
    };
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setPromisesComplete(0);
    setPromises([]);

    if (hasChanges()) {
      const details = {
        title,
        description,
        auto_delete_events_prior: autoDeleteEventsPrior,
      };
      setProgressMessage("calendar.updating.title_and_description");
      updateCalendar(id, details)
        .then((res) => {
          dispatch(updateCalendarDetails(id, details));
          if (userListChanged) {
            updatePermittedUsers();
          } else {
            requestComplete(res);
          }
        })
        .catch(requestComplete);
    } else if (userListChanged) {
      updatePermittedUsers();
    }
  };

  const updatePermittedUsers = () => {
    const userIds = newPermissionedUsers.map(({ id }) => id);
    setProgressMessage("calendar.updating.permitted_users");
    permitUsers(id, userIds)
      .then((res) => {
        setUserListChanged(false);
        const newPromises = reEncryptAppointments(res.data);
        if (newPromises.length) {
          setProgressMessage("calendar.updating.keystores_progress_display");
          newPromises.forEach((p) =>
            p.then((e) => setPromisesComplete(promisesComplete + 1))
          );
          setPromisesComplete(0);
          setPromises(newPromises);
          Promise.all(newPromises)
            .then((res) => {
              setProgressMessage("calendar.updating.complete");
              setApiResponse(null);
              setPromisesComplete(0);
              setPromises([]);
            })
            .catch(requestComplete);
        } else {
          setProgressMessage("");
        }
      })
      .catch(requestComplete);
  };

  const requestComplete = (res) => {
    setProgressMessage("");
    setApiResponse(res);
  };

  const verifyCalendarDelete = () => {
    const acceptedEvents = calendar.attributes.events_with_appointments.filter(
      (e) => e.status == "accepted"
    );
    if (acceptedEvents.length) {
      store.dispatch(
        showConfirmationModal({
          title: t(`calendar.not_yet_deletable`),
          description: t(`calendar.not_yet_deletable_more`),
          proceed: confirmNotYetDeleteableCallback,
        })
      );
    } else {
      store.dispatch(
        showConfirmationModal({
          title: t(`confirmation_required`),
          description: t(`calendar.really_delete`),
          proceed: confirmDeleteCallback,
        })
      );
    }
  };

  const confirmNotYetDeleteableCallback = () => {
    dispatch(hideConfirmationModal());
  };

  const confirmDeleteCallback = (value) => {
    dispatch(hideConfirmationModal());
    if (value) {
      deleteCalendar(id)
        .then(() => {
          getCalendars();
          history.push("/calendars");
        })
        .catch(setApiResponse);
    }
  };

  const onUserListUpdate = (newUsers) => {
    setNewPermissionedUsers(newUsers);
    setUserListChanged(true);
    setProgressMessage("");
  };

  if (!calendar) return null;
  if (!hasRight(ACCESS_RIGHTS.invite_ttks))
    return <p>{t("calendar.access_disabled")}</p>;

  const hasChanges = () => {
    return (
      calendar.attributes.title !== title ||
      calendar.attributes.description !== description ||
      calendar.attributes.auto_delete_events_prior !== autoDeleteEventsPrior ||
      userListChanged
    );
  };

  return (
    <div>
      <h2>{title}</h2>
      {id && <CalendarLink id={id} />}
      <hr />
      <AuthCode>
        <form onSubmit={onSubmit} acceptCharset="UTF-8" method="get">
          <LabeledRow label={t("calendar.create.title")} required={true}>
            <input
              type="text"
              name="calendar_title"
              value={title}
              className="form-control"
              onChange={(event) => {
                setProgressMessage("");
                setTitle(event.target.value);
              }}
            />
          </LabeledRow>
          <LabeledRow label={t("calendar.create.description")}>
            <input
              name="calendar_description"
              value={description}
              className="form-control"
              onChange={(event) => {
                setProgressMessage("");
                setDescription(event.target.value);
              }}
            />
          </LabeledRow>

          <LabeledRow label={t("calendar.auto_delete_events_prior")}>
            <input
              name="calendar_auto_delete_events_prior"
              value={autoDeleteEventsPrior}
              className="form-control"
              onChange={(event) => {
                setProgressMessage("");
                setAutoDeleteEventsPrior(event.target.value);
              }}
            />
          </LabeledRow>

          <LabeledRow label={t("calendar.permitted_users")}>
            <CalendarPermissions
              permissionedUsers={newPermissionedUsers}
              onUpdate={onUserListUpdate}
            />
          </LabeledRow>

          <NotificationLayer
            apiResponse={apiResponse}
            customTrigger={apiResponse}
          />
          <div>
            {progressMessage && (
              <p>
                {t(progressMessage, {
                  total: promises.length,
                  completed: promisesComplete,
                })}
              </p>
            )}
            {!!promises.length && promisesComplete < promises.length && (
              <ProgressBar
                striped
                variant="success"
                now={(promises.length / promisesComplete) * 100}
              />
            )}
          </div>

          <Button
            type="submit"
            disabled={!hasChanges() || progressMessage}
            className="ghost-button white-button-invers"
          >
            {t("update")}
          </Button>
        </form>
      </AuthCode>

      {role === UserRole.HTK && (
        <>
          <hr />
          <DeleteButton onClick={() => verifyCalendarDelete(id)} />
        </>
      )}
    </div>
  );
};

CalendarEdit.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
