import React from "react";
import { LabeledRow } from "../common/LabeledRow";

function TenantFormFields({ formik }) {
  const inputElement = (field) => {
    let content;
    const props = {
      name: field,
      type: "string",
      className: "form-control",
      onChange: formik.handleChange,
      defaultValue: formik.values[field],
    };

    switch (true) {
      case field.startsWith("mailer_"):
        return "";
      case field === "email_footer":
        content = <textarea {...props} rows="6"></textarea>;
        break;
      case field === "appointment_quick_accept":
      case field === "show_member_map":
        content = (
          <div>
            <input
              {...props}
              type="checkbox"
              defaultChecked={!!formik.values[field]}
            />
          </div>
        );
        break;
      default:
        content = <input {...props} />;
    }
    return (
      <LabeledRow label={field} key={field}>
        {content}
      </LabeledRow>
    );
  };

  return (
    <>
      {window.ITREF.config.tenant_fields.map(inputElement)}
      <LabeledRow label="Logo" key="logo">
        <input
          type="file"
          accept="image/png, image/jpeg"
          name="logo"
          className="form-control"
          onChange={(event) => {
            formik.setFieldValue("logo", event.currentTarget);
          }}
        />
      </LabeledRow>
    </>
  );
}

export default TenantFormFields;
