import { useTranslation } from "react-i18next";
import { displayDate } from "../../dateFunctions";

export const RuntimeDisplay = ({ startTimeDate, endTimeDate, duration }) => {
  const [t] = useTranslation();

  const dateOption = { hour: "2-digit", minute: "2-digit" };
  return (
    <div>
      {displayDate(startTimeDate, dateOption)} {t("until")}{" "}
      {displayDate(endTimeDate, dateOption)} ({duration} {t("minutes")})
    </div>
  );
};
