import ax from "../packs/axios";

export const getTenants = async () => {
  const res = await ax.get("tenants");
  return res;
};

export const getTenant = (id) => {
  return ax.get(`tenants/${id}`);
};

export const createTenant = async (formData) => {
  const res = await ax.post("tenants", formData);
  return res;
};

export const updateTenant = async (formData) => {
  const res = await ax.put("tenants", formData, { headers: {
    "Content-Type": "multipart/form-data",
  }});
  return res;
};
