import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ACCESS_RIGHTS, UserRole } from "../constants";
import ax from "../packs/axios";
/**
 * Get list of all users or filtered by params
 * @param {number} lastUpdate | when changed, triggers an update
 * @param {object} params | URL params to filter. Accepted parameters "password_reset_status", "status" or "user_role"
 */
export const useUsers = (lastUpdate, params) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      let apiPath = "/users";
      if (params) apiPath += "?" + new URLSearchParams(params).toString();
      const fetchData = async () => {
        const response = await ax.get(apiPath);
        setLoading(false);
        setData(response.data.data);
      };
      fetchData();
    } catch (e) {
      setError(e);
      setData([]);
    }
  }, [lastUpdate]);
  return [data, loading, error];
};

/**
 * Returns user role of currently logged in user, otherwise empty
 * @returns string
 */
export const useUserRole = () => {
  const user = useCurrentUser();
  if (user) return user.user_role;
  return "";
};

/**
 * Returns currently logged in user from state.dashboard
 * @returns object
 */
export const useCurrentUser = () => {
  const [cu, setCU] = useState(0);
  const { currentUser } = useSelector((state) => state.dashboard);

  useEffect(() => {
    setCU(currentUser);
  }, [currentUser]);

  return cu;
};

export const useSelectedUser = () => {
  const [user, setUser] = useState({});
  const { selectedUser } = useSelector((state) => state.appState);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  return user;
};

export const useRights = () => {
  const user = useCurrentUser();
  const [rights, setRights] = useState([]);

  useEffect(() => {
    if (!user) setRights([]);
    if (user.user_role === UserRole.HTK) {
      setRights(Object.keys(ACCESS_RIGHTS));
    } else if (user.user_role === UserRole.TTK) {
      setRights(user.rights);
    } else {
      setRights([]);
    }
  }, [user]);

  return rights;
};
