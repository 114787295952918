import { getI18n } from "react-i18next";
import { updateCurrentEvent, updateShowModal } from "./actions/app";
import { simpleDecryptPrivateKey } from "./actions/decryption";
import { ModalID, UserRole } from "./constants";
import { EventStatus } from "./eventStatus";
import { store } from "./store/store";
import forge from "node-forge";
/**
 * function for defaulting to an empty string if the object of an attribute is undefined
 * @param {string} attribute
 * @param {object} obj
 * @returns empty string or passed in attribute of the obj
 */
export const attributeOrFallback = (obj, attribute) => {
  return obj ? obj[attribute] : "";
};

/**
 * function for checking if an Object is empty thus as no key values pairs
 * @param {object} obj
 */
export const isEmptyObject = (obj) => {
  return !Object.keys(obj).length;
};

/**
 * Updates appState with new event and shows event modal
 * @param {object} event
 */
export const openEventModal = (event) => {
  store.dispatch(updateCurrentEvent(event));
  store.dispatch(updateShowModal(ModalID.EVENT));
};

/**
 * @param {string} geocode | comma-seperated latitude and longitude
 * @returns Object with lat and lng for geo_coding
 */
export const latlngforMap = (geo_code) => {
  const geo_coords = geo_code.split(",");
  const lat = parseFloat(geo_coords[0]);
  const lng = parseFloat(geo_coords[1]);
  return { lat: lat, lng: lng };
};

export const hasEncryption = (status) => {
  return status !== EventStatus.CREATED && status !== EventStatus.BLOCKED;
};

/**
 * Returns the localized short day name from index, i.e. 0 = Mon
 * @param {number} index
 */
export const getDayByIndex = (index) => {
  return getI18n().t("dates.abbr_day_names")[index];
};

/**
 * Extract start and end date from start_hour, start_minute and duration properties
 * @param {object} values
 * @returns array with startTimeDate and endTimeDate date objects
 */
export const getEventSeriesDates = (values) => {
  if (
    !values ||
    empty(values.start_hour) ||
    empty(values.start_minute) ||
    !values.duration
  ) {
    return [];
  }
  const startTimeDate = new Date();
  startTimeDate.setHours(
    parseInt(values.start_hour),
    parseInt(values.start_minute),
    0,
    0
  );

  const endTimeDate = new Date(startTimeDate);
  endTimeDate.setMinutes(endTimeDate.getMinutes() + parseInt(values.duration));
  return [startTimeDate, endTimeDate];
};

const empty = (value) => {
  return typeof value === "undefined" || value === null || value === "";
};

/**
 * Return formatted name string
 * @param {object} | ansb_user
 * @returns string with last name and first name, i.e. Smith, John
 */
export const getDisplayName = ({ email, first_name, last_name }) => {
  return `${email}(${last_name}, ${first_name})`;
};

/**
 * Returns true if either HTK or current user has this right
 * @param {string} neededRight
 */
export const hasRight = (neededRight) => {
  const { currentUser } = store.getState().dashboard;
  if (!currentUser) return false;
  return (
    currentUser.user_role === UserRole.HTK ||
    currentUser.rights?.includes(neededRight)
  );
};

/**
 * Return the decrypted private key for this user
 * Requires authCode and currentUser in redux store, password in localStorage
 * @returns result of decryptRsaPrivateKey from forge
 */
export const getDecryptedPrivateKey = () => {
  const { authCode } = store.getState().appState;
  const { currentUser } = store.getState().dashboard;

  return simpleDecryptPrivateKey(currentUser.private_key, authCode);
};

export const verifyCredentials = (user, pw, auth) => {
  return new Promise((resolve, reject) => {
    try {
      const password = pw + auth;
      const pki = forge.pki;
      const decryptedPrivateKey = pki.decryptRsaPrivateKey(
        user.private_key,
        password
      );

      if (decryptedPrivateKey != "" && decryptedPrivateKey != null) {
        resolve();
      } else {
        reject();
      }
    } catch (e) {
      console.log(e);
      reject();
    }
  });
};

/**
 * calculate duration between 2 dates in full hours
 * @param {date} start
 * @param {date} end
 * @returns number
 */
export function durationInMs(start, end) {
  return (
      end.getTime() - start.getTime()
  );
}

/**
 * checks max allowed duration in Hour between two dates
 * @param {date} start
 * @param {date} end
 * @param {number} durationHour
 * @returns boolean
 */
export function checkMaxDurationInHour(start, end, durationHour) {
  return (
      durationInMs(start, end) < durationHour*60*60*1000
  );
}

/**
 * set a cookie
 * @param {string} name
 * @param {string} value
 * @param {number} days
 */
export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

/**
 * get a cookie value
 * @param {string} name
 * @returns value or null
 */
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

/**
 * Retrieve title of calendar by its ID
 * @param {number} calId
 * @returns Calendar Name or ID
 */
export function getCalendarName (calId) {
  const { calendars } = store.getState().dashboard
  if (!calendars) return calId;
  if(calId === 'none') return getI18n().t("event.series.no_calendar")
  const cal = calendars.find((c) => c.id === calId);
  return cal?.attributes?.title || calId;
};


var yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

/**
 * Sorts and array of events by their calendar id
 * @param {array} events
 */
export function sortByCalendars(events) {
  let newList = {};
  events.forEach((entry) => {
    const { calendar_id, end } = entry.attributes
    // ditch old events
    var endDate = new Date(end)
    if(endDate.getTime() < yesterday.getTime()) return
    if (!newList[calendar_id]) newList[calendar_id] = [];
    newList[calendar_id].push({id: entry.id, ...entry.attributes});
  });
  return newList
}

/**
 * Merges ID with attributes from response
 * @param {axios promise} response
 */
export function storableUser(response) {
  const userDetails = {
    ...response.data.data.attributes,
    id: response.data.data.id,
  };
  return userDetails;
}

/**
 *
 * @param {string} url
 * @returns url without https or http
 */
export function slimURL(url) {
  return url.replace(/(^\w+:|^)\/\//, '');
}
